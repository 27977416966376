/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion'

import SEO from 'src/components/organisms/SEO'
import { H1 } from 'src/components/atoms/text'
import { ml } from 'src/gatsby-plugin-theme-ui/utils'
import {
  AnimationScrollYFix,
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
} from 'src/animations'

const NotFoundPage = () => {
  return (
    <div>
      <SEO
        is404={true}
        title="Not Found"
        image=""
        description="Page not found"
        path=""
        // alternate_languages=""
        lang=""
      />
      <AnimationScrollYFix />
      <motion.div {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER })}>
        <H1 as="h1" sx={{ mt: ml('s', 'l'), mb: 400 }}>
          Page not found
        </H1>
      </motion.div>
    </div>
  )
}

NotFoundPage.defaultProps = {
  pageContext: { __layout: { navTextColor: 'text', hideBottomBlock: true } },
}

export default NotFoundPage

/*
// If an unpublished document is previewed, these templates will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    project: ProjectTemplate,
    prismicProject: ProjectTemplate,
    homepage: HomePage
    // TODO delete after testing preview is working
    // prismicHomepage: HomePage,
    // Project: ProjectTemplate,
    // PrismicProject: ProjectTemplate,
    // Homepage: HomePage,
    // PrismicHomepage: HomePage
  }
})
 */
